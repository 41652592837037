import Web3 from "web3";
import loadOnce from "./async";

export const getPublicWeb3 = loadOnce(
  () =>
    new Web3(
      process.env.NODE_ENV === "production"
        ? "https://eth-mainnet.alchemyapi.io/v2/sImp96a3Ly2GODlEsgyhMP1HIsQtEoZn"
        : "http://127.0.0.1:7545",
    ),
);

/**
 * Returns a web3 instance connected to the user's wallet, prompting them for
 * access if they haven't yet given it. Caches the result: subsequent calls to
 * `getWalletWeb3` will return the same instance.
 */
export const getWalletWeb3 = loadOnce(createWalletWeb3);

async function createWalletWeb3(): Promise<Web3> {
  const anyWindow: any = window;
  await waitForWindowLoad();
  // Modern dapp browsers...
  if (anyWindow.ethereum) {
    const web3 = new Web3(anyWindow.ethereum);
    // Request account access if needed
    await anyWindow.ethereum.enable();
    // Acccounts now exposed
    return web3;
  }
  // Legacy dapp browsers...
  else if (anyWindow.web3) {
    // Use Mist/MetaMask's provider.
    const web3 = anyWindow.web3;
    console.log("Injected web3 detected.");
    return web3;
  }
  // Fallback to localhost; use dev console port by default...
  else {
    const provider = new Web3.providers.HttpProvider("http://127.0.0.1:9545");
    const web3 = new Web3(provider);
    console.log("No web3 instance injected, using Local web3.");
    return web3;
  }
}

function waitForWindowLoad(): Promise<void> {
  if (document.readyState === "complete") {
    return Promise.resolve();
  }
  return new Promise((resolve) =>
    window.addEventListener("load", () => resolve()),
  );
}
