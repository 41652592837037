import Web3 from "web3";
import CompetitionContract from "../../../build/contracts/DappKingsCompetition.json";
import { DappKingsCompetition } from "../declarations/generated/typechain/DappKingsCompetition";

export function getCompetitionContract(
  web3: Web3,
): Promise<DappKingsCompetition> {
  return createContract(web3, CompetitionContract);
}

async function createContract<T>(web3: Web3, contractSpec: any): Promise<T> {
  const networkId = await web3.eth.net.getId();
  const deployedNetwork = contractSpec.networks[networkId];
  return new web3.eth.Contract(
    contractSpec.abi,
    deployedNetwork?.address,
  ) as any;
}
