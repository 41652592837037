import axios, { AxiosError, AxiosResponse } from "axios";
import { assertNever } from "../util/typeAssertions";

const DEV_BASE_URL = "http://localhost:8080";

export interface ExpressErrorResponse {
  readonly message: string;
  readonly name?: string;
}

export enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export const VIEWING_AS_WRITE_ERROR_MESSAGE =
  "Cannot write while viewing as someone else.";

export function callEndpoint<T>(
  method: Method,
  endpoint: string,
  params?: unknown,
): Promise<T> {
  return rawCallEndpoint<T>(method, endpoint, params).then(
    getAxiosResponseData,
    rethrowAxiosInternalError,
  );
}

function rawCallEndpoint<T>(
  method: Method,
  endpoint: string,
  params?: any,
): Promise<AxiosResponse<T>> {
  if (process.env.NODE_ENV !== "production") {
    endpoint = DEV_BASE_URL + endpoint;
  }

  switch (method) {
    case Method.GET:
      return axios.get(endpoint, { params });
    case Method.POST:
      return axios.post(endpoint, params);
    case Method.PUT:
      return axios.put(endpoint, params);
    case Method.DELETE:
      return axios.delete(endpoint, { params });
    default:
      return assertNever(method);
  }
}

function getAxiosResponseData<T>(response: AxiosResponse<T>) {
  return response.data;
}

function rethrowAxiosInternalError(error: AxiosError): never {
  throw getAxiosInternalError(error);
}

function getAxiosInternalError(wrappedError: AxiosError): Error {
  const errorResponse: AxiosResponse<ExpressErrorResponse> | undefined =
    wrappedError.response;
  if (!errorResponse) {
    return new Error();
  }
  const { name, message } = errorResponse.data;
  const error = new Error(message);
  if (name) {
    error.name = name;
  }
  return error;
}

export function configureAxios(): void {
  axios.defaults.headers.common.Accept = "application/json";
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
  // Attach Django CSRF token
  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.xsrfHeaderName = "X-CSRFToken";
}
