import { Action, Reducer, combineReducers } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { chainFrom } from "transducist";
import { actionCreatorFactory } from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import {
  getAddresses,
  getValidTokens,
  getSubmissionDeadline,
  getCompetitionEndTime,
  getContestInfo,
} from "../http/endpoints";
import {
  Loadable,
  makeLoadingActionCreators,
  makeFetchThunkActionCreator,
  reducerForLoadable,
} from "./loadable";

export interface RootState {
  tokens: Loadable<Token[]>;
  selectedTokens: TokenSelection[];
  addresses: Loadable<string[]>;
  submissionDeadline: Loadable<number>;
  competitionEndTime: Loadable<number>;
  contestInfo: Loadable<ContestInfo>;
}

export interface Token {
  address: TokenAddress;
  name: string;
  symbol: string;
  ethPrice: number;
  ethMarketCap: number;
  url: string | undefined;
}

export interface TokenSelection {
  tokenAddress: TokenAddress;
  allocation: number;
}

export interface ContestInfo {
  entryFee: number;
  entryCount: number;
}

export type Branded<A, B> = A & { _brand: B };

export type TokenAddress = Branded<string, "TokenAddress">;

export type AppDispatch = ThunkDispatch<RootState, undefined, Action>;
export type AppThunkAction<R> = ThunkAction<R, RootState, undefined, Action>;

export const TOTAL_ALLOCATION = 1000;
export const MAX_SELECTED_TOKENS = 5;
export const MIN_ALLOCATION = 10;
export const MAX_ALLOCATION =
  TOTAL_ALLOCATION - MIN_ALLOCATION * (MAX_SELECTED_TOKENS - 1);

const actionCreator = actionCreatorFactory();

const requestTokensAction = makeLoadingActionCreators<void, Token[]>(
  "REQUEST_TOKENS",
);

const requestAddressesAction = makeLoadingActionCreators<void, string[]>(
  "REQUEST_ADDRESSES",
);

const requestSubmissionDeadline = makeLoadingActionCreators<void, number>(
  "REQUEST_SUBMISSION_DEADLINE",
);

const requestCompetitionEndTime = makeLoadingActionCreators<void, number>(
  "REQUEST_COMPETITION_END_TIME",
);

const requestContestInfo = makeLoadingActionCreators<void, ContestInfo>(
  "REQUEST_CONTEST_INFO",
);

export const fetchTokensAction = makeFetchThunkActionCreator<void, Token[]>({
  actionCreators: requestTokensAction,
  getFromState: (state) => state.tokens,
  fetchResult: getValidTokens,
});

export const fetchAddressesAction = makeFetchThunkActionCreator<void, string[]>(
  {
    actionCreators: requestAddressesAction,
    getFromState: (state) => state.addresses,
    fetchResult: getAddresses,
  },
);

export const fetchSubmissionDeadlineAction = makeFetchThunkActionCreator<
  void,
  number
>({
  actionCreators: requestSubmissionDeadline,
  getFromState: (state) => state.submissionDeadline,
  fetchResult: getSubmissionDeadline,
});

export const fetchCompetitionEndTimeAction = makeFetchThunkActionCreator<
  void,
  number
>({
  actionCreators: requestCompetitionEndTime,
  getFromState: (state) => state.competitionEndTime,
  fetchResult: getCompetitionEndTime,
});

export const fetchContestInfoAction = makeFetchThunkActionCreator<
  void,
  ContestInfo
>({
  actionCreators: requestContestInfo,
  getFromState: (state) => state.contestInfo,
  fetchResult: getContestInfo,
});

export const selectTokenAction = actionCreator<TokenAddress>("SELECT_TOKEN");
export const unselectTokenAction = actionCreator<TokenAddress>(
  "UNSELECT_TOKEN",
);

export interface SetAllocationPayload {
  tokenAddress: TokenAddress;
  allocation: number;
}

export const setAllocationAction = actionCreator<SetAllocationPayload>(
  "SET_ALLOCATION",
);
export const clearSelectedTokensAction = actionCreator("CLEAR_SELECTED_TOKENS");

export const rootReducer: Reducer<RootState> = combineReducers({
  tokens: reducerForLoadable(requestTokensAction),
  addresses: reducerForLoadable(requestAddressesAction),
  selectedTokens: reducerWithInitialState<TokenSelection[]>([])
    .case(selectTokenAction, handleSelectToken)
    .case(unselectTokenAction, handleUnselectToken)
    .case(setAllocationAction, handleSetAllocation)
    .case(clearSelectedTokensAction, handleClearSelectedTokens)
    .build(),
  submissionDeadline: reducerForLoadable(requestSubmissionDeadline),
  competitionEndTime: reducerForLoadable(requestCompetitionEndTime),
  contestInfo: reducerForLoadable(requestContestInfo),
});

function handleSelectToken(
  selectedTokens: TokenSelection[],
  tokenAddress: TokenAddress,
): TokenSelection[] {
  if (selectedTokens.some((token) => token.tokenAddress === tokenAddress)) {
    throw new Error("Cannot select an already-selected token.");
  }
  if (selectedTokens.length === MAX_SELECTED_TOKENS) {
    throw new Error(`Cannot select more than ${MAX_SELECTED_TOKENS} tokens.`);
  }
  let allocation = TOTAL_ALLOCATION / MAX_SELECTED_TOKENS;
  if (selectedTokens.length === MAX_SELECTED_TOKENS - 1) {
    // Special handling for adding the last token. Give it all the remaining
    // allocation if less than its normal fair share.
    const remainingAllocation = getRemainingAllocation(selectedTokens);
    allocation = clampAllocation(remainingAllocation);
  }
  return [...selectedTokens, { tokenAddress, allocation }];
}

function handleUnselectToken(
  selectedTokens: TokenSelection[],
  tokenAddress: TokenAddress,
): TokenSelection[] {
  const result = selectedTokens.filter(
    (token) => token.tokenAddress !== tokenAddress,
  );
  if (result.length === selectedTokens.length) {
    throw new Error("Cannot unselect a token that is not selected.");
  }
  return result;
}

function handleSetAllocation(
  selectedTokens: TokenSelection[],
  { tokenAddress, allocation }: SetAllocationPayload,
): TokenSelection[] {
  if (!Number.isInteger(allocation)) {
    throw new Error("Allocation must be an integer.");
  }
  const index = selectedTokens.findIndex(
    (token) => token.tokenAddress === tokenAddress,
  );
  if (index === -1) {
    throw new Error("Cannot set allocation of unselected token.");
  }
  return [
    ...selectedTokens.slice(0, index),
    { ...selectedTokens[index], allocation },
    ...selectedTokens.slice(index + 1),
  ];
}

function handleClearSelectedTokens(): TokenSelection[] {
  return [];
}

export function getRemainingAllocation(
  selectedTokens: TokenSelection[],
): number {
  return (
    TOTAL_ALLOCATION -
    chainFrom(selectedTokens)
      .map((token) => token.allocation)
      .sum()
  );
}

export function isValidAllocation(selectedTokens: TokenSelection[]): boolean {
  return (
    selectedTokens.length === MAX_SELECTED_TOKENS &&
    getRemainingAllocation(selectedTokens) === 0 &&
    selectedTokens.every(
      (token) =>
        MIN_ALLOCATION <= token.allocation &&
        token.allocation <= MAX_ALLOCATION,
    )
  );
}

export function clampAllocation(allocation: number): number {
  return Math.max(MIN_ALLOCATION, Math.min(allocation, MAX_ALLOCATION));
}

export function getPrizePool(contestInfo: ContestInfo): number {
  return contestInfo.entryCount * contestInfo.entryFee;
}
