import { chainFrom, range } from "transducist";
import { Token, TokenSelection, ContestInfo } from "../redux/root";
import { getCompetitionContract } from "../util/contracts";
import { getWalletWeb3, getPublicWeb3 } from "../util/web3";
import { callEndpoint, Method } from "./axiosConfig";

export function getValidTokens(): Promise<Token[]> {
  return callEndpoint(Method.GET, "/get-valid-tokens").then(
    (result: any) => result.tokens,
  );
}

export function getAddresses(): Promise<string[]> {
  return getWalletWeb3().then((web3) => web3.eth.getAccounts());
}

export async function getSubmissionDeadline(): Promise<number> {
  const competition = await getCompetitionContract(getPublicWeb3());
  const deadline = await competition.methods.getSubmissionDeadline().call();
  return +deadline * 1000;
}

export async function getCompetitionEndTime(): Promise<number> {
  const competition = await getCompetitionContract(getPublicWeb3());
  const endTime = await competition.methods.getCurrentCompetitionEnd().call();
  return +endTime * 1000;
}

export async function getContestInfo(): Promise<ContestInfo> {
  const competition = await getCompetitionContract(getPublicWeb3());
  const {
    entry_fee,
    num_entries,
  } = await competition.methods.current_competition().call();
  return { entryFee: weiToEth(entry_fee), entryCount: +num_entries };
}

export async function enterContest(
  entryFee: number,
  selectedTokens: TokenSelection[],
): Promise<void> {
  const web3 = await getWalletWeb3();
  const competition = await getCompetitionContract(web3);
  const accounts = await web3.eth.getAccounts();
  if (accounts.length === 0) {
    throw new Error("Wallet has no accounts.");
  }
  const from = accounts[0];
  await competition.methods
    .enterContest(
      from,
      selectedTokens.map((token) => [
        token.tokenAddress,
        ethToWei(token.allocation),
      ]),
    )
    .send({ from, value: ethToWei(entryFee) });
}

function weiToEth(wei: string): number {
  // Amounts of wei are too large to fit into a number. Convert to gwei first
  // via string manipulation.
  const gwei = wei.length > 9 ? +wei.slice(0, wei.length - 9) : 0;
  return gwei / 1e9;
}

const NINE_ZEROES = chainFrom(range(9))
  .map(() => "0")
  .joinToString("");

function ethToWei(eth: number): string {
  if (eth === 0) {
    return "0";
  }
  const gwei = Math.ceil(eth * 1e9);
  return `${gwei}${NINE_ZEROES}`;
}
