/** @jsx jsx */
import { ReactElement } from "react";
import { Provider } from "react-redux";
import { applyMiddleware, createStore, Middleware, Store } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import { jsx } from "theme-ui";

import { rootReducer, RootState } from "./root";

// See https://www.gatsbyjs.org/docs/adding-redux-store/

export default function wrapWithProviders({
  element,
}: {
  element: ReactElement;
}): ReactElement {
  const store = createReduxStore();
  return <Provider store={store}>{element}</Provider>;
}

function createReduxStore(): Store<RootState> {
  const middlewares: Middleware[] = [thunk];
  if (process.env.NODE_ENV !== "production") {
    middlewares.push(createLogger({ collapsed: true }));
  }
  process.env.NODE_ENV === "production"
    ? []
    : [createLogger({ collapsed: true })];
  return createStore(rootReducer, applyMiddleware(...middlewares));
}
