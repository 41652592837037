export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const UNSET = Symbol("unset");

export default function loadOnce<T>(load: () => T): () => T {
  let value: T | typeof UNSET = UNSET;
  return () => {
    if (value === UNSET) {
      value = load();
    }
    return value;
  };
}
